<template>
  <a-layout-header>
    <div class="page-menu" v-if="showHone">
      <div class="menu-top">
        <div class="menu-top-img">
          <router-link to="/">
            <img src="/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="menu-top-operation">
          <div class="conversion">
            <span>
              <a
                href="javascript:zh_tran('s');"
                class="zh_click"
                id="zh_click_s"
                style="text-decoration:none;color:#fff;"
              >简</a>
            </span>
            <span>/</span>
            <span>
              <a
                href="javascript:zh_tran('t');"
                class="zh_click"
                id="zh_click_t"
                style="text-decoration:none;color:#fff;"
              >繁</a>
            </span>
          </div>
          <div class="in" v-if="no">
            <span @click="showModal">登录</span>
            <span>/</span>
            <span @click="showRegister">注册</span>
          </div>
          <div class="in my" v-if="yes">
            <span @click="myBtn">我的</span>
            <span>/</span>
            <span @click="exit">退出</span>
          </div>
        </div>
      </div>
      <div class="navigation">
        <a-menu mode="horizontal">
          <!-- <a-menu-item key="mail">
          <router-link to>Navigation One</router-link>
          </a-menu-item>-->
          <a-sub-menu v-for="item in lists" :key="item.id">
            <span slot="title" class="submenu-title-wrapper">
              <router-link
                :to="item.id == 5 ?item.link_url + '/35': item.id == 6 ? item.link_url + '/55' : item.link_url"
                :class="getHomeColor(item)"
              >{{ item.cate_name }}</router-link>
            </span>

            <a-menu-item-group v-if="item.id === 2" style="padding-top:0;margin-top:0;">
              <a-menu-item
                v-for="child in item.children"
                :key="child.id"
                style="border-bottom: 1px solid #ccc;margin: 0;padding-right:0;"
              >
                <router-link :to="child.link_url + '/' + child.id">{{ child.cate_name }}</router-link>
              </a-menu-item>
            </a-menu-item-group>
          </a-sub-menu>
        </a-menu>
        <div class="navigation-search">
          <a-input-search
            placeholder
            style="width: 100%"
            v-model="search"
            :allowClear="true"
            @search="onSearch"
          />
          <div class="search-jg" v-if="searchlist.length!==0">
            <div class="jg">
              <div
                class="two"
                @click="btnSearch(item.id)"
                v-for="item in searchlist"
                :key="item.id"
              >
                <a>{{item.title}}</a>
              </div>
            </div>
            <div class="position"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="forum-menu" v-if="shoeForum">
      <div class="menu-top">
        <div class="menu-top-img">
          <router-link to="/">
            <img src="/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="menu-top-operation">
          <div class="conversion">
            <span>
              <a
                href="javascript:zh_tran('s');"
                class="zh_click"
                id="zh_click_s"
                style="text-decoration:none;color:#fff;"
              >简</a>
            </span>
            <span>/</span>
            <span>
              <a
                href="javascript:zh_tran('t');"
                class="zh_click"
                id="zh_click_t"
                style="text-decoration:none;color:#fff;"
              >繁</a>
            </span>
          </div>
          <div class="in" v-if="no">
            <span @click="showModal">登录</span>
            <span>/</span>
            <span @click="showRegister">注册</span>
          </div>
          <div class="my in" v-if="yes">
            <span @click="myBtn">我的</span>
            <span>/</span>
            <span @click="exit">退出</span>
          </div>
        </div>
      </div>
      <div class="navigation">
        <a-menu v-model="uli" mode="horizontal">
          <a-menu-item v-for="item in forumLists" :key="item.id" @click.native="to(item)">
            <router-link
              :to="item.id == 42 ? '' : item.id == 24 ? item.link_url :item.id ==36?item.link_url + '/55': item.id == 41 ? item.link_url + '/53':item.id == 43 ? item.link_url + '/70': item.link_url + '/' + item.id"
              :class="getColor(item)"
            >{{ item.cate_name }}</router-link>
          </a-menu-item>
        </a-menu>
        <div class="navigation-search">
          <a-input-search
            placeholder
            style="width:100%"
            v-model="searchF"
            :allowClear="true"
            @search="onSearch"
          />
          <div class="search-jg" v-if="searchFlist.length!==0">
            <div class="jg">
              <div
                class="two"
                @click="btnSearchF(item.id)"
                v-for="item in searchFlist"
                :key="item.id"
              >
                <a>{{item.title}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Login v-model="visible" @isLogin="isLogin"></Login>
    <Register v-model="registerVisible"></Register>
  </a-layout-header>
</template>
<script>
import Login from '../components/login';
import Register from '../components/Register';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    Login,
    Register,
  },
  data() {
    return {
      yes: false,
      no: true,
      visible: false,
      registerVisible: false,
      lists: [],
      forumLists: [],
      uli: [''],
      showHone: true,
      shoeForum: false,
      conversionUrl: window.location.pathname,
      searchlist: [],
      searchFlist: [],
      search: '',
      searchF: '',
    };
  },
  created() {
    this.$router.afterEach((to, from) => {
      this.conversionUrl = to.fullPath;
      if (to.fullPath.startsWith('/forum/')) {
        this.shoeForum = true;
        this.showHone = false;
      } else {
        this.showHone = true;
        this.shoeForum = false;
      }
    });
    if (window.location.pathname.startsWith('/forum/')) {
      this.shoeForum = true;
      this.showHone = false;
    } else {
      this.showHone = true;
      this.shoeForum = false;
    }
  },
  mounted() {
    this.getLogin();

    this.menu();
    this.forumMenu();
  },
  watch: {
    search(n) {
      if (!n) {
        this.searchlist = [];
      }
    },
    searchF(n) {
      if (!n) {
        this.searchFlist = [];
      }
    },
  },
  computed: {
    ...mapState('user', ['info']),
  },
  methods: {
    myBtn() {
      this.$router.push('/my');
    },
    //
    getColor(item) {
      //  :class="item.id == 24item.link_url === conversionUrl ? 'active' : ''"

      if (item.id == 24) {
        return item.link_url === this.conversionUrl ? 'active' : '';
      } else if (item.id == 36) {
        return item.link_url + '/55' === this.conversionUrl ? 'active' : '';
      } else if (item.id == 41) {
        return item.link_url + '/53' === this.conversionUrl ? 'active' : '';
      } else if (item.id == 43) {
        return item.link_url + '/70' === this.conversionUrl ? 'active' : '';
      } else {
        return item.link_url + '/' + item.id === this.conversionUrl ? 'active' : '';
      }
    },
    getHomeColor(item) {
      //:class="item.link_url === conversionUrl ? 'active' : ''"
      if (item.id == 5) {
        return item.link_url + '/35' === this.conversionUrl ? 'active' : '';
      } else if (item.id == 6) {
        return item.link_url + '/55' === this.conversionUrl ? 'active' : '';
      } else {
        return item.link_url === this.conversionUrl ? 'active' : '';
      }
    },
    //
    to(item) {
      if (item.id == 42) {
        window.open(item.link_url);
      }
    },
    //是否登录
    isLogin(e) {
      if (e) {
        this.no = false;
        this.yes = true;
      } else {
        this.no = true;
        this.yes = false;
      }
      // console.log(data);
      if (!_.isEmpty(this.info)) {
        this.no = false;
        this.yes = true;
      } else {
        this.no = true;
        this.yes = false;
      }
    },
    getLogin() {
      this.isLogin();
    },
    menu() {
      return this.$request(Apis.mun.MUNS).then(data => {
        this.lists = data.data.data;
        // this.onRouterChange(this.$route);
      });
    },
    forumMenu() {
      return this.$request(Apis.mun.FORUMMUNS).then(data => {
        this.forumLists = data.data.data;
        // console.log(this.forumLists);
        // this.onRouterChangeForumMenu(this.$route);
      });
    },
    onSearch(e) {
      if (!e) {
        return;
      }

      if (this.showHone) {
        return this.$request(Apis.home.SEARCHS, { data: { title: this.search } })
          .then(data => {
            this.searchlist = data.data.arts1;
          })
          .finally(() => {});
      } else {
        return this.$request(Apis.home.SEARCHSF, { data: { title: this.searchF } })
          .then(data => {
            this.searchFlist = data.data.post;
          })
          .finally(() => {});
      }
    },
    btnSearch(id) {
      this.$router.push('/details/' + id);
    },
    btnSearchF(id) {
      this.$router.push('/forum/details/' + id);
    },
    showModal() {
      this.visible = true;
    },
    showRegister() {
      this.registerVisible = true;
    },
    exit() {
      let data = {
        id: '' + this.info.id,
        token: this.info.token,
      };
      return this.$request(Apis.login.EXIT, { data: data }).then(data => {
        if (data.data.status === 200) {
          this.$message.success('退出成功');
          this.no = true;
          this.yes = false;
          let data = {};
          localStorage.setItem('exit', JSON.stringify(data));
          this.login(JSON.parse(localStorage.getItem('exit')));
        } else {
          this.$message.warning(data.data.msg);
        }
      });
    },
    ...mapMutations('user', ['login']),
  },
};
</script>
<style lang="less" scoped>
.active {
  color: #ffcc00 !important;
}
</style>
<style lang="less" scoped>
.navigation-search {
  position: relative;
  .search-jg {
    color: #ccc;
    position: relative;

    .jg {
      padding: 10px;
      position: absolute;
      border: 1px solid #ccc;
      border-radius: 10px;
      z-index: 99;
      width: 100%;
      top: 10px;
      background: #fff;
      .two {
        font-size: 16px;
        padding-left: 10px;
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
        a {
          color: #ccc;
        }
        a:hover {
          color: #ffcc00;
        }
      }
    }
  }
}
</style>
