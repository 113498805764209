var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    [
      _c("header-layout"),
      _c(
        "a-layout-content",
        [
          _c("router-view"),
          _vm.num >= 768
            ? _c("div", { staticClass: "ant-layout-content-position" }, [
                _vm.isAnchor
                  ? _c("div", { staticClass: "Anchor" }, [
                      _c(
                        "a",
                        { attrs: { href: "#is-full-height" } },
                        [_c("a-icon", { attrs: { type: "to-top" } })],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "return", on: { click: _vm.btnreturn } },
                  [_c("a-icon", { attrs: { type: "rollback" } })],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("footer-layout")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }