<template>
  <div class="login">
    <a-modal :footer="null" :visible="visible" @cancel="handleCancel">
      <div v-show="islogin">
        <div class="account">
          <div class="wen">账号</div>
          <div class="input">
            <a-input placeholder="用户名" v-model="account" />
          </div>
        </div>
        <div class="password">
          <div class="wen">密码</div>
          <div class="input">
            <a-input type="password" v-model="password" />
          </div>
        </div>
        <div class="operation">
          <div class="forget" @click="btnForget">忘记密码？</div>
          <div class="remember">
            <a-checkbox @change="onChange">记住我</a-checkbox>
          </div>
        </div>
        <div class="btn" @click="handleOk">登录</div>
      </div>
      <div v-show="forget">
        <div class="forget-title">忘记密码</div>
        <div class="password">
          <div class="wen">手机号</div>
          <div class="input">
            <a-input v-model="mobile" />
          </div>
        </div>
        <div class="btn" @click="btnValidation">点击验证</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
import { mapMutations } from 'vuex';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
  },
  data() {
    return {
      account: '',
      password: '',
      mobile: '',
      islogin: true,
      forget: false,
    };
  },
  methods: {
    //登录
    handleOk() {
      if (_.isEmpty(this.password)) {
        this.$message.warning('请输入密码');
        return;
      }
      if (_.isEmpty(this.account)) {
        this.$message.warning('请输入账号');
        return;
      }
      let data = {
        username: this.account,
        password: this.password,
      };
      return this.$request(Apis.login.LOGIN, { data: data })
        .then(data => {
          if (data.data.status === 200) {
            this.$message.success('登录成功');
            this.account = '';
            this.password = '';

            this.loginSuccess(data.data.data);
          } else {
            this.$message.warning(data.data.msg);
          }
        })
        .catch(e => {});
    },
    loginSuccess(data) {
      localStorage.setItem('data', JSON.stringify(data));
      this.login(JSON.parse(localStorage.getItem('data')));
      this.$emit('change', false);
      this.$emit('isLogin', true);
    },
    handleCancel(e) {
      this.$emit('change', false);
      this.islogin = true;
      this.forget = false;
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
    //忘记密码
    btnForget() {
      this.islogin = false;
      this.forget = true;
    },
    btnValidation() {
      if (_.isEmpty(this.mobile)) {
        this.$message.warning('请输入手机号');
        return;
      }
      return this.$request(Apis.login.FORGET, { data: { mobile: +this.mobile } })
        .then(data => {
          if (data.data.status === 200) {
            this.$message.success('新密码将以手机短信息的方式稍后发送到您的手机');
            this.mobile = '';
            this.islogin = true;
             this.forget = false;
            this.$emit('change', false);
          } else {
            this.$message.warning(data.data.msg);
          }
        })
        .catch(e => {});
    },
    ...mapMutations('user', ['login']),
  },
};
</script>
<style lang="less" scoped>
.account,
.password,
.operation {
  display: flex;

  width: 60%;
  margin: auto;
  margin-bottom: 30px;
  .wen {
    font-size: 20px;
    font-weight: 600;
    color: #666666;
    margin-right: 10px;
  }
  .input {
    flex: 1;
  }
}
.operation {
  justify-content: space-between;
  .forget {
    cursor: pointer;
  }
}
.btn {
  width: 30%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 10px;
  background: #181818;
  margin: auto;
  color: #fff;
}
.forget-title {
  text-align: center;
  color: #ffcc00;
  font-size: 24px;
  margin: 30px 0;
}
</style>
