var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout-footer", [
    _c("footer", { staticClass: "footer" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center"
          }
        },
        [
          _c(
            "a-menu",
            { staticStyle: { width: "50%" }, attrs: { mode: "horizontal" } },
            _vm._l(_vm.menu, function(item) {
              return _c(
                "a-menu-item",
                {
                  key: item.id,
                  on: {
                    click: function($event) {
                      return _vm.to(item)
                    }
                  }
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          item.id == 58
                            ? ""
                            : item.id == 59
                            ? ""
                            : item.link_url
                      }
                    },
                    [_vm._v(_vm._s(item.cate_name))]
                  )
                ],
                1
              )
            }),
            1
          ),
          _c("div", { staticClass: "share", staticStyle: { width: "20%" } }, [
            _c("div", { attrs: { id: "share-2" } })
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "13px",
                color: "#fff",
                margin: "40px 0",
                width: "50%"
              }
            },
            [
              _c("div", { staticStyle: { margin: "5px 0" } }, [
                _vm._v("\n          佳库版权所有:\n          "),
                _vm.record
                  ? _c("span", [_vm._v(_vm._s(_vm.record.keyword))])
                  : _vm._e()
              ]),
              _c("div", { staticStyle: { margin: "5px 0" } }, [
                _vm._v("\n          隐私条款:\n          "),
                _vm.record
                  ? _c("span", [_vm._v(_vm._s(_vm.record.description))])
                  : _vm._e()
              ]),
              _c("div", { staticStyle: { margin: "5px 0" } }, [
                _vm._v("\n          备案号:\n          "),
                _vm.record
                  ? _c("span", [_vm._v(_vm._s(_vm.record.record))])
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "footer-img",
              staticStyle: { "text-align": "center", width: "20%" }
            },
            [
              _c("img", {
                staticStyle: { margin: "auto" },
                attrs: { src: "/assets/images/footer-logo.png" }
              })
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }