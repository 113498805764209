<template>
  <a-layout>
    <header-layout />
    <a-layout-content>
      <router-view />
      <div class="ant-layout-content-position" v-if="num>=768">
        <div class="Anchor" v-if="isAnchor">
          <a href="#is-full-height">
            <a-icon type="to-top" />
          </a>
        </div>
        <div class="return" @click="btnreturn">
          <a-icon type="rollback" />
        </div>
      </div>
    </a-layout-content>
    <footer-layout />
  </a-layout>
</template>

<script>
import HeaderLayout from './HeaderLayout';
import FooterLayout from './FooterLayout';
export default {
  components: {
    HeaderLayout,
    FooterLayout,
  },
  data() {
    return {
      isAnchor: false,
      num: 0,
    };
  },
  created() {
    this.num = window.screen.width;
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      //改变元素#searchBar的top值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop >= 200) {
        this.isAnchor = true;
      } else {
        this.isAnchor = false;
      }
    },
    btnreturn() {
      window.history.back(-1);
    },
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style lang="less">
.ant-layout-content {
  position: relative;
  .ant-layout-content-position {
    position: fixed;
    top: 50%;
    right: 15%;
    color: #ccc;
    font-size: 40px;
    .Anchor {
      a {
        color: #ccc;
      }
    }
  }
}
</style>
