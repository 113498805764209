<template>
  <a-layout-footer>
    <footer class="footer">
      <div style="display: flex; align-items: center;justify-content: center;">
        <a-menu mode="horizontal" style="width: 50%;">
          <a-menu-item v-for="item in menu" :key="item.id" @click="to(item)">
            <router-link :to="item.id==58?'':item.id==59?'':item.link_url">{{item.cate_name}}</router-link>
          </a-menu-item>
        </a-menu>
        <div class="share" style="width: 20%;">
          <div id="share-2"></div>
        </div>
      </div>
      <div style="display: flex; align-items: center;justify-content: center;">
        <div style="font-size: 13px;color: #fff;margin: 40px 0;width: 50%;">
          <div style="margin: 5px 0;">
            佳库版权所有:
            <span v-if="record">{{record.keyword}}</span>
          </div>
          <div style="margin: 5px 0;">
            隐私条款:
            <span v-if="record">{{record.description}}</span>
          </div>
          <div style="margin: 5px 0;">
            备案号:
            <span v-if="record">{{record.record}}</span>
          </div>
        </div>
        <div class="footer-img" style="text-align:center;width: 20%;">
          <img style="margin:auto" src="/assets/images/footer-logo.png" />
        </div>
      </div>
    </footer>
  </a-layout-footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      now: new Date(),
      record: {},
      menu: [],
    };
  },
  mounted() {
    this.share();
    this.getrecord();
    this.getmenu();
  },
  methods: {
    to(item) {
      if (item.id == 58) {
        window.open(item.link_url);
        return;
      }
      if (item.id == 59) {
        window.open(item.link_url);
        return;
      }
    },
    share() {
      $('#share-2').share({ sites: ['qq', 'weibo', 'wechat'] });
    },
    getrecord() {
      return this.$request(Apis.home.CONFS).then(data => {
        this.record = data.data.data;
      });
    },
    getmenu() {
      return this.$request(Apis.home.CATEDB).then(data => {
        this.menu = data.data.data;
      });
    },
  },
  computed: {},
};
</script>
