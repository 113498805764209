var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rigister" },
    [
      _c(
        "a-modal",
        {
          attrs: { footer: null, visible: _vm.visible },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c("div", { staticClass: "account" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("姓氏")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  model: {
                    value: _vm.surNames,
                    callback: function($$v) {
                      _vm.surNames = $$v
                    },
                    expression: "surNames"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("名字")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  model: {
                    value: _vm.givenName,
                    callback: function($$v) {
                      _vm.givenName = $$v
                    },
                    expression: "givenName"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("地区")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("v-distpicker", {
                  attrs: {
                    province: _vm.diglogForm.province,
                    city: _vm.diglogForm.city,
                    area: _vm.diglogForm.area,
                    wrapper: "registerRegion"
                  },
                  on: {
                    province: _vm.onChangeProvince,
                    city: _vm.onChangeCity,
                    area: _vm.onChangeArea
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("用户名")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  on: {
                    blur: function($event) {
                      $event.preventDefault()
                      return _vm.checkName($event)
                    }
                  },
                  model: {
                    value: _vm.formerName,
                    callback: function($$v) {
                      _vm.formerName = $$v
                    },
                    expression: "formerName"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c(
                  "a-radio-group",
                  {
                    on: { change: _vm.onChangeRadio },
                    model: {
                      value: _vm.radioValue,
                      callback: function($$v) {
                        _vm.radioValue = $$v
                      },
                      expression: "radioValue"
                    }
                  },
                  [
                    _c("a-radio", { attrs: { value: 1 } }, [
                      _vm._v("个人注册")
                    ]),
                    _c("a-radio", { attrs: { value: 2 } }, [_vm._v("公司注册")])
                  ],
                  1
                ),
                _c("div", [_vm._v("注:(公司注册有论坛积分奖励)")])
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCompany,
                  expression: "isCompany"
                }
              ]
            },
            [
              _c("div", { staticClass: "password" }, [
                _c("div", { staticClass: "wen" }, [_vm._v("公司地址")]),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.companyAdress,
                        callback: function($$v) {
                          _vm.companyAdress = $$v
                        },
                        expression: "companyAdress"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "password" }, [
                _c("div", { staticClass: "wen" }, [_vm._v("公司名称")]),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.companyName,
                        callback: function($$v) {
                          _vm.companyName = $$v
                        },
                        expression: "companyName"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "password" }, [
                _c("div", { staticClass: "wen" }, [_vm._v("联系方式")]),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.companyPhone,
                        callback: function($$v) {
                          _vm.companyPhone = $$v
                        },
                        expression: "companyPhone"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("从事方向")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100%" },
                    on: { change: _vm.handleChangeWork }
                  },
                  [
                    _vm._l(_vm.works, function(item) {
                      return [
                        _c("a-select-option", { key: item.id }, [
                          _vm._v(_vm._s(item.name))
                        ])
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("密码")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  attrs: { type: "password" },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("确认密码")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  attrs: { type: "password" },
                  on: {
                    blur: function($event) {
                      $event.preventDefault()
                      return _vm.checkpPassword($event)
                    }
                  },
                  model: {
                    value: _vm.passwordOk,
                    callback: function($$v) {
                      _vm.passwordOk = $$v
                    },
                    expression: "passwordOk"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("电子邮箱")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  on: {
                    blur: function($event) {
                      $event.preventDefault()
                      return _vm.blurEmail($event)
                    }
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("手机号码")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  on: {
                    blur: function($event) {
                      $event.preventDefault()
                      return _vm.checkphone($event)
                    }
                  },
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }, [_vm._v("验证码")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-input", {
                  on: {
                    blur: function($event) {
                      $event.preventDefault()
                      return _vm.blurCode($event)
                    }
                  },
                  model: {
                    value: _vm.code,
                    callback: function($$v) {
                      _vm.code = $$v
                    },
                    expression: "code"
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.obtain,
                      expression: "obtain"
                    }
                  ],
                  staticClass: "obtain",
                  attrs: { type: "button", disabled: _vm.disabled },
                  domProps: { value: _vm.obtain },
                  on: {
                    click: _vm.getObtain,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.obtain = $event.target.value
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "password" }, [
            _c("div", { staticClass: "wen" }),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("a-checkbox", { on: { change: _vm.onChangeAgreement } }, [
                  _vm._v("同意网站协议")
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "btn", on: { click: _vm.handleOk } }, [
            _vm._v("注册")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }