<template>
  <div class="rigister">
    <a-modal :footer="null" :visible="visible" @cancel="handleCancel">
      <div class="account">
        <div class="wen">姓氏</div>
        <div class="input">
          <a-input v-model="surNames" />
        </div>
      </div>
      <div class="password">
        <div class="wen">名字</div>
        <div class="input">
          <a-input v-model="givenName" />
        </div>
      </div>

      <div class="password">
        <div class="wen">地区</div>
        <div class="input">
          <v-distpicker
            :province="diglogForm.province"
            :city="diglogForm.city"
            :area="diglogForm.area"
            @province="onChangeProvince"
            @city="onChangeCity"
            @area="onChangeArea"
            wrapper="registerRegion"
          ></v-distpicker>
        </div>
      </div>
      <div class="password">
        <div class="wen">用户名</div>
        <div class="input">
          <a-input v-model="formerName" @blur.prevent="checkName" />
        </div>
      </div>
      <div class="password">
        <div class="wen"></div>
        <div class="input">
          <a-radio-group @change="onChangeRadio" v-model="radioValue">
            <a-radio :value="1">个人注册</a-radio>
            <a-radio :value="2">公司注册</a-radio>
          </a-radio-group>
          <div>注:(公司注册有论坛积分奖励)</div>
        </div>
      </div>

      <div v-show="isCompany">
        <div class="password">
          <div class="wen">公司地址</div>
          <div class="input">
            <a-input v-model="companyAdress" />
          </div>
        </div>
        <div class="password">
          <div class="wen">公司名称</div>
          <div class="input">
            <a-input v-model="companyName" />
          </div>
        </div>
        <div class="password">
          <div class="wen">联系方式</div>
          <div class="input">
            <a-input v-model="companyPhone" />
          </div>
        </div>
      </div>
      <div class="password">
        <div class="wen">从事方向</div>
        <div class="input">
          <a-select @change="handleChangeWork" style="width: 100%;">
            <template v-for="item in works">
              <a-select-option :key="item.id">{{ item.name }}</a-select-option>
            </template>
          </a-select>
        </div>
      </div>
      <div class="password">
        <div class="wen">密码</div>
        <div class="input">
          <a-input type="password" v-model="password" />
        </div>
      </div>
      <div class="password">
        <div class="wen">确认密码</div>
        <div class="input">
          <a-input type="password" v-model="passwordOk" @blur.prevent="checkpPassword" />
        </div>
      </div>
      <div class="password">
        <div class="wen">电子邮箱</div>
        <div class="input">
          <a-input v-model="email" @blur.prevent="blurEmail" />
        </div>
      </div>
      <div class="password">
        <div class="wen">手机号码</div>
        <div class="input">
          <a-input v-model="phone" @blur.prevent="checkphone" />
        </div>
      </div>
      <div class="password">
        <div class="wen">验证码</div>
        <div class="input">
          <a-input v-model="code" @blur.prevent="blurCode" />
          <input
            type="button"
            class="obtain"
            @click="getObtain"
            :disabled="disabled"
            v-model="obtain"
          />
        </div>
      </div>
      <div class="password">
        <div class="wen"></div>
        <div class="input">
          <a-checkbox @change="onChangeAgreement">同意网站协议</a-checkbox>
        </div>
      </div>
      <div class="btn" @click="handleOk">注册</div>
    </a-modal>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
import VDistpicker from 'v-distpicker';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
  },
  components: { VDistpicker },
  data() {
    return {
      surNames: '',
      givenName: '',
      diglogForm: {
        province: null,
        city: null,
        area: null,
      },
      region: '',
      formerName: '',
      radioValue: 1,
      workid: '',
      isCompany: false,
      companyAdress: '',
      companyName: '',
      companyPhone: '',
      password: '',
      passwordOk: '',
      email: '',
      phone: '',
      code: '',
      iscode: false,
      isAgreement: '',
      works: [],
      disabled: false,
      obtain: '获取验证码',
      totalTime: 60,
      isusername: false,
      isphone:false,
    };
  },
  mounted() {
    this.work();
  },
  methods: {
    checkName() {
      return this.$request(Apis.register.USERNAME, { data: { username: this.formerName } })
        .then(data => {
          console.log(data.data.status);
          if (data.data.status === 200) {
            this.isusername = true;
          } else {
            this.$message.warning('用户名重复，请重新输入');
            this.isusername = false;
          }
        })
        .catch(e => {
          this.$message.warning(e);
        });
    },
    checkphone() {
      return this.$request(Apis.register.METHOD, { data: { mobile: this.phone } })
        .then(data => {
          console.log(data.data.status);
          if (data.data.status === 200) {
            this.isphone = true;
          } else {
            this.$message.warning('手机号重复，请重新输入');
            this.isphone = false;
          }
        })
        .catch(e => {
          this.$message.warning(e);
        });
    },
    checkpPassword() {
      if (this.password != this.passwordOk) {
        this.$message.warning('请检查密码是否一致');
      }
    },
    blurEmail() {
      var emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (!emailReg.test(this.email)) {
        this.$message.warning('email有误，请重填');
        return;
      }
    },
    work() {
      return this.$request(Apis.register.WORKS).then(data => {
        this.works = data.data.data;
      });
    },
    handleCancel(e) {
      this.$emit('change', false);
    },
    //省市区联动
    // onSelectedRegion(data) {
    //   this.region = data.province.value + data.city.value + data.area.value;
    //   // console.log(this.region);
    // },
    onChangeProvince(data) {
      this.diglogForm.province = data.value;
    },
    onChangeCity(data) {
      this.diglogForm.city = data.value;
    },
    onChangeArea(data) {
      this.diglogForm.area = data.value;
    },
    onChangeRadio(e) {
      this.radioValue = e.target.value;
      if (e.target.value === 1) {
        this.isCompany = false;
      } else {
        this.isCompany = true;
      }
    },
    handleChangeWork(e) {
      this.workid = e;
    },
    onChangeAgreement(e) {
      this.isAgreement = e.target.checked;
      // console.log(`checked = ${e.target.checked}`);
    },
    //验证码
    getObtain() {
      // console.log('' + this.phone);
      if (_.isEmpty('' + this.phone)) {
        this.$message.warning('请输手机号');
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.warning('手机号码有误，请重填');
        return;
      }
      return this.$request(Apis.login.CODE, { data: { mobile: this.phone } }).then(data => {
        this.time();
      });
    },
    time() {
      this.disabled = true;
      this.obtain = '重新发出(' + this.totalTime + ')';
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.obtain = '重新发出(' + this.totalTime + ')';
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.obtain = '获取验证码';
          this.totalTime = 60;
          this.disabled = false;
        }
      }, 1000);
    },
    //验证验证码
    blurCode() {
      return this.$request(Apis.login.YZCODE, { data: { yzcode: this.code } })
        .then(data => {
          console.log(data.data.status);
          if (data.data.status === 200) {
            this.iscode = true;
          } else {
            this.$message.warning('验证码不正确，请重新输入验证码');
            this.iscode = false;
          }
        })
        .catch(e => {
          this.$message.warning(e);
        });
    },
    //注册
    handleOk(e) {
      if (!this.isAgreement) {
        this.$message.warning('请勾选网站协议');
        return;
      }
      if (_.isEmpty(this.surNames)) {
        this.$message.warning('请输入姓氏');
        return;
      }
      if (_.isEmpty(this.givenName)) {
        this.$message.warning('请输入名字');
        return;
      }
      if (!this.diglogForm.province && !this.diglogForm.city && !this.diglogForm.area) {
        this.$message.warning('请选择地区');
        return;
      }

      if (_.isEmpty(this.formerName)) {
        this.$message.warning('请输入用户名');
        return;
      }
       if (!this.isusername) {
        this.$message.warning('用户名重复，请重新输入');
        return;
      }
      if (_.isEmpty(this.passwordOk)) {
        this.$message.warning('请输入密码');
        return;
      }
      if (_.isEmpty(this.email)) {
        this.$message.warning('请输入电子邮箱');
        return;
      }
      if (_.isEmpty(this.phone)) {
        this.$message.warning('请输手机号');
        return;
      }
       if (!this.isphone) {
        this.$message.warning('手机号重复，请重新输入');
        return;
      }
      if (_.isEmpty(this.code)) {
        this.$message.warning('请输入验证码');
        return;
      }
      if (this.password != this.passwordOk) {
        this.$message.warning('请检查密码是否一致');
      }
     
     
      this.region = this.diglogForm.province + this.diglogForm.city + this.diglogForm.area;
      let data = {
        xing: this.surNames,
        name: this.givenName,
        province: this.region,
        username: this.formerName,
        leixing: '' + this.radioValue,
        password: '' + this.passwordOk,
        email: this.email,
        mobile: this.phone,
        code: '' + this.code,
      };
      console.log(this.iscode);
      if (this.iscode) {
        if (this.radioValue == 1) {
          if (_.isEmpty('' + this.workid)) {
            this.$message.warning('请选择从事方向');
            return;
          }
          data.fangxiang = '' + this.workid;
          this.$request(Apis.register.PERSONAL, { data: data }).then(data => {
            if (data.data.status === 200) {
              this.$message.success('个人注册成功');
              this.$emit('change', false);
              this.surNames = '';
              this.givenName = '';
              diglogForm = {
                province: null,
                city: null,
                area: null,
              };
              this.region = '';
              this.formerName = '';
              this.radioValue = 1;
              this.password = '';
              this.passwordOk = '';
              this.email = '';
              this.phone = '';
              this.code = '';
              this.workid = '';
            } else {
              this.$message.success(data.data.msg);
            }
          });
        } else if (this.radioValue == 2) {
          if (_.isEmpty(this.companyAdress)) {
            this.$message.warning('请输入公司地址');
            return;
          }
          if (_.isEmpty(this.companyName)) {
            this.$message.warning('请输入公司名称');
            return;
          }
          if (_.isEmpty(this.companyPhone)) {
            this.$message.warning('请输入公司联系方式');
            return;
          }
          data.fangxiang = '' + this.workid;
          data.address = this.companyAdress;
          data.company = this.companyName;
          data.phone = this.companyPhone;
          this.$request(Apis.register.COMPANY, { data: data }).then(data => {
            if (data.data.status === 200) {
              this.$message.success('公司注册成功');
              this.$emit('change', false);
              this.surNames = '';
              this.givenName = '';
              diglogForm = {
                province: null,
                city: null,
                area: null,
              };
              this.region = '';
              this.formerName = '';
              this.radioValue = 1;
              this.password = '';
              this.passwordOk = '';
              this.email = '';
              this.phone = '';
              this.code = '';
              this.workid = '';
              this.companyAdress = '';
              this.companyName = '';
              this.companyPhone = '';
            } else {
              this.$message.success(data.data.msg);
            }
          });
        }
      } else {
        this.$message.warning('验证码不正确，请重新输入验证码');
      }
    },
  },
};
</script>
<style lang="less">
.registerRegion select {
  width: 100%;
  line-height: 32px;
  height: 32px;
  margin-bottom: 10px;
}
.registerRegion select:hover {
  border-color: #ffdb29;
  border-right-width: 1px !important;
}
.registerRegion select:focus {
  border-color: #ffdb29;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 204, 0, 0.2);
  border-right-width: 1px !important;
}
</style>
<style lang="less" scoped>
.account,
.password,
.operation {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
  .wen {
    font-size: 20px;
    font-weight: 600;
    color: #666666;
    margin-right: 10px;
    text-align: right;
    width: 20%;
  }
  .input {
    width: 45%;
    position: relative;
    .registerRegion {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .obtain {
      position: absolute;
      height: 100%;
      top: 50%;
      right: 0;
      margin-top: -16px;
      line-height: 32px;
      padding: 0 5px;
      z-index: 99;
      border: 1px solid #ccc;
      /* flex: 1; */
      width: 50%;
      text-align: center;
      cursor: pointer;
      outline: none;
    }
  }
}
.operation {
  justify-content: space-between;
  .forget {
    cursor: pointer;
  }
}
.btn {
  width: 30%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 10px;
  background: #181818;
  margin: auto;
  color: #fff;
}
.forget-title {
  text-align: center;
  color: #ffcc00;
  font-size: 24px;
  margin: 30px 0;
}
</style>
