var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-header",
    [
      _vm.showHone
        ? _c("div", { staticClass: "page-menu" }, [
            _c("div", { staticClass: "menu-top" }, [
              _c(
                "div",
                { staticClass: "menu-top-img" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", { attrs: { src: "/assets/images/logo.png" } })
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "menu-top-operation" }, [
                _c("div", { staticClass: "conversion" }, [
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "zh_click",
                        staticStyle: {
                          "text-decoration": "none",
                          color: "#fff"
                        },
                        attrs: {
                          href: "javascript:zh_tran('s');",
                          id: "zh_click_s"
                        }
                      },
                      [_vm._v("简")]
                    )
                  ]),
                  _c("span", [_vm._v("/")]),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "zh_click",
                        staticStyle: {
                          "text-decoration": "none",
                          color: "#fff"
                        },
                        attrs: {
                          href: "javascript:zh_tran('t');",
                          id: "zh_click_t"
                        }
                      },
                      [_vm._v("繁")]
                    )
                  ])
                ]),
                _vm.no
                  ? _c("div", { staticClass: "in" }, [
                      _c("span", { on: { click: _vm.showModal } }, [
                        _vm._v("登录")
                      ]),
                      _c("span", [_vm._v("/")]),
                      _c("span", { on: { click: _vm.showRegister } }, [
                        _vm._v("注册")
                      ])
                    ])
                  : _vm._e(),
                _vm.yes
                  ? _c("div", { staticClass: "in my" }, [
                      _c("span", { on: { click: _vm.myBtn } }, [
                        _vm._v("我的")
                      ]),
                      _c("span", [_vm._v("/")]),
                      _c("span", { on: { click: _vm.exit } }, [_vm._v("退出")])
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "navigation" },
              [
                _c(
                  "a-menu",
                  { attrs: { mode: "horizontal" } },
                  _vm._l(_vm.lists, function(item) {
                    return _c(
                      "a-sub-menu",
                      { key: item.id },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "submenu-title-wrapper",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [
                            _c(
                              "router-link",
                              {
                                class: _vm.getHomeColor(item),
                                attrs: {
                                  to:
                                    item.id == 5
                                      ? item.link_url + "/35"
                                      : item.id == 6
                                      ? item.link_url + "/55"
                                      : item.link_url
                                }
                              },
                              [_vm._v(_vm._s(item.cate_name))]
                            )
                          ],
                          1
                        ),
                        item.id === 2
                          ? _c(
                              "a-menu-item-group",
                              {
                                staticStyle: {
                                  "padding-top": "0",
                                  "margin-top": "0"
                                }
                              },
                              _vm._l(item.children, function(child) {
                                return _c(
                                  "a-menu-item",
                                  {
                                    key: child.id,
                                    staticStyle: {
                                      "border-bottom": "1px solid #ccc",
                                      margin: "0",
                                      "padding-right": "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: child.link_url + "/" + child.id
                                        }
                                      },
                                      [_vm._v(_vm._s(child.cate_name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "navigation-search" },
                  [
                    _c("a-input-search", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "", allowClear: true },
                      on: { search: _vm.onSearch },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _vm.searchlist.length !== 0
                      ? _c("div", { staticClass: "search-jg" }, [
                          _c(
                            "div",
                            { staticClass: "jg" },
                            _vm._l(_vm.searchlist, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "two",
                                  on: {
                                    click: function($event) {
                                      return _vm.btnSearch(item.id)
                                    }
                                  }
                                },
                                [_c("a", [_vm._v(_vm._s(item.title))])]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "position" })
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.shoeForum
        ? _c("div", { staticClass: "forum-menu" }, [
            _c("div", { staticClass: "menu-top" }, [
              _c(
                "div",
                { staticClass: "menu-top-img" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", { attrs: { src: "/assets/images/logo.png" } })
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "menu-top-operation" }, [
                _c("div", { staticClass: "conversion" }, [
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "zh_click",
                        staticStyle: {
                          "text-decoration": "none",
                          color: "#fff"
                        },
                        attrs: {
                          href: "javascript:zh_tran('s');",
                          id: "zh_click_s"
                        }
                      },
                      [_vm._v("简")]
                    )
                  ]),
                  _c("span", [_vm._v("/")]),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "zh_click",
                        staticStyle: {
                          "text-decoration": "none",
                          color: "#fff"
                        },
                        attrs: {
                          href: "javascript:zh_tran('t');",
                          id: "zh_click_t"
                        }
                      },
                      [_vm._v("繁")]
                    )
                  ])
                ]),
                _vm.no
                  ? _c("div", { staticClass: "in" }, [
                      _c("span", { on: { click: _vm.showModal } }, [
                        _vm._v("登录")
                      ]),
                      _c("span", [_vm._v("/")]),
                      _c("span", { on: { click: _vm.showRegister } }, [
                        _vm._v("注册")
                      ])
                    ])
                  : _vm._e(),
                _vm.yes
                  ? _c("div", { staticClass: "my in" }, [
                      _c("span", { on: { click: _vm.myBtn } }, [
                        _vm._v("我的")
                      ]),
                      _c("span", [_vm._v("/")]),
                      _c("span", { on: { click: _vm.exit } }, [_vm._v("退出")])
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "navigation" },
              [
                _c(
                  "a-menu",
                  {
                    attrs: { mode: "horizontal" },
                    model: {
                      value: _vm.uli,
                      callback: function($$v) {
                        _vm.uli = $$v
                      },
                      expression: "uli"
                    }
                  },
                  _vm._l(_vm.forumLists, function(item) {
                    return _c(
                      "a-menu-item",
                      {
                        key: item.id,
                        nativeOn: {
                          click: function($event) {
                            return _vm.to(item)
                          }
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            class: _vm.getColor(item),
                            attrs: {
                              to:
                                item.id == 42
                                  ? ""
                                  : item.id == 24
                                  ? item.link_url
                                  : item.id == 36
                                  ? item.link_url + "/55"
                                  : item.id == 41
                                  ? item.link_url + "/53"
                                  : item.id == 43
                                  ? item.link_url + "/70"
                                  : item.link_url + "/" + item.id
                            }
                          },
                          [_vm._v(_vm._s(item.cate_name))]
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "navigation-search" },
                  [
                    _c("a-input-search", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "", allowClear: true },
                      on: { search: _vm.onSearch },
                      model: {
                        value: _vm.searchF,
                        callback: function($$v) {
                          _vm.searchF = $$v
                        },
                        expression: "searchF"
                      }
                    }),
                    _vm.searchFlist.length !== 0
                      ? _c("div", { staticClass: "search-jg" }, [
                          _c(
                            "div",
                            { staticClass: "jg" },
                            _vm._l(_vm.searchFlist, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "two",
                                  on: {
                                    click: function($event) {
                                      return _vm.btnSearchF(item.id)
                                    }
                                  }
                                },
                                [_c("a", [_vm._v(_vm._s(item.title))])]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("Login", {
        on: { isLogin: _vm.isLogin },
        model: {
          value: _vm.visible,
          callback: function($$v) {
            _vm.visible = $$v
          },
          expression: "visible"
        }
      }),
      _c("Register", {
        model: {
          value: _vm.registerVisible,
          callback: function($$v) {
            _vm.registerVisible = $$v
          },
          expression: "registerVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }